//TODO: check why the photos are not loading or saving correctly
import React, { useState, useEffect, forwardRef, useRef } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    
    InputGroupAddon,
    InputGroupText,
    
    Input as RSInput,
} from 'reactstrap';
import { AdvancedTable, Button, ButtonGroup, DeletionModal, Dropdown, FormRow, Input, InputGroup, Switch } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import HelpPopup from '../../components/helpPopup';
import PageLoad from '../../components/PageLoad';
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import UpdateModal from '../../components/UpdateModal';
import { Editor } from '@tinymce/tinymce-react';

const InputButton = forwardRef(({ value, onClick }, ref) => (
    <Button onClick={onClick} innerRef={ref}>
        {value}
    </Button>
));
InputButton.displayName = "InputButton"

function EditEvent(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [title, setTitle] = useState("")
    const [selectedBranches, setSelectedBranches] = useState([])
    const [startDate, setStartDate] = useState(new Date(new Date().setHours(0,0,0,0)))
    const [endDate, setEndDate] = useState(new Date((new Date().setHours(0,0,0,0)) + 86400000));
    const [sponsers, setSponsers] = useState([])
    const [storeFolderId, setStoreFolderId] = useState("")
    const [selectedFrameType, setSelectedFrameType] = useState({value: 1, label: "Frame 1"})
    
    const [zipping, isZipping] = useState(false)

    const [appParams, setAppParams] = useState([{label: "", paramName: ""}])

    const [fileImageFrame1, setFileImageFrame1] = useState("")
    const [fileImageFrameName1, setImageFrameName1] = useState("")

    const [fileImageFrame2, setFileImageFrame2] = useState("")
    const [fileImageFrameName2, setImageFrameName2] = useState("")

    const [availaleCompanies, setAvailableCompanies] = useState([]);
    const [branches, setBranches] = useState([])
    const [videos, setVideos] = useState([])

    const [guestAppImage, setGuestAppImage] = useState("")
    const [guestAppImageName, setGuestAppImageName] = useState("")
    const [guestAppColor, setGuestAppColor] = useState("")
    const [guestAppTopText, setGuestAppTopText] = useState("")
    const [guestAppBottomText, setGuestAppBottomText] = useState("")

    const [tableValues, setTableValues] = useState([]);
    const [tableNote, setTableNote] = useState("");
    const [loading, setLoading] = useState(false);


    const [contentEditor, setContentEditor] = useState("<p>החלק העליון</p>");
        const handleEditorChange = (content, editor) => {
            console.log('Content was updated:', content);
            setContentEditor(content);
        }
        const editorRef = useRef(null);
        const log = () => {
            if (editorRef.current) {
            console.log(editorRef.current.getContent());
            }
        };
    const [contentEditor1, setContentEditor1] = useState("<p>החלק התחתון</p>");
        const handleEditorChange1 = (content, editor) => {
            console.log('Content was updated:', content);
            setContentEditor1(content);
        }
        const editorRef1 = useRef(null);
        const log1 = () => {
            if (editorRef1.current) {
            console.log(editorRef1.current.getContent());
            }
        };

    useEffect(() => {
        if(isEdit) {
            refreshValues();
            const interval = setInterval(() => {
                refreshValues();
            }, 30000);
            return () => clearInterval(interval);
        }
    }, [socket, isEdit]);

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];
        if (socket !== null && socket.isOpened) {
            socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")).then(res => {
                setAvailableCompanies(res.response);
            })
            socket.sendRequest(prepareSystemServiceQuery("GET", "branch")).then(branchesInfo => {
                setBranches(branchesInfo.response.map(branch => ({value: branch._id, label: branch.name})))
            })
            
            socket.sendRequest(prepareSystemServiceQuery("GET", "events", {...(ruleObj ? { id: ruleObj } : {})})).then(res => {
                Object.keys(res.response).length > 0 && res.response.forEach(item => {
                    if(item._id === ruleObj) {
                        setIsEdit(true);
                        setEditId(item._id);
                        setTitle(item.title);
                        setSelectedBranches(item.participatingBranches.map(branch => ({value: branch._id, label: branch.name})))
                        setStartDate(new Date(item.startDate));
                        setEndDate(new Date(item.endDate));
                        setImageFrameName1(item.imageFrame1)
                        setImageFrameName2(item.imageFrame2)
                        setSponsers(item.sponsers)
                        setStoreFolderId(item.firebaseFolderId)
                        setSelectedFrameType({value: item.selectedFrameType, label: `Frame ${item.selectedFrameType}`})
                        if(item.guestAppColor !== undefined) setGuestAppColor(item.guestAppColor)
                        if(item.guestAppTopText !== undefined) setContentEditor(item.guestAppTopText)
                        if(item.guestAppBottomText !== undefined) setContentEditor1(item.guestAppBottomText)
                        console.log(item.firebaseFolderId)
                        socket.sendRequest(prepareSystemServiceQuery("GET", "firebase_storage", {folderId: item._id})).then(storageItems => {
                            setVideos(storageItems.response)
                            //setBranches(branchesInfo.response.map(branch => ({value: branch._id, label: branch.name})))
                        })
                        setDataLoading(false)
                    }
                })
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                    //setMessage("Page Not Found, ID does not exist, you can create a new page.")
                }
            })
        }
    }, [socket]);

    function refreshValues() {
        if(socket !== null && socket.isOpened) {
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "firebase", {collection: ["events", storeFolderId, storeFolderId]})).then(res => {
                if(res.response !== undefined) {
                    setTableValues(res.response);
                }
                setLoading(false)
            }).catch(() => setLoading(false));
            setLoading(true)
            socket.sendRequest(prepareSystemServiceQuery("GET", "firebase_storage", {folderId: editId})).then(storageItems => {
                setVideos(storageItems.response)
                setLoading(false)
                //setBranches(branchesInfo.response.map(branch => ({value: branch._id, label: branch.name})))
            })
        }
    }

    function deleteValue(cell) {
        if (socket !== null && socket.isOpened) {
            setTableValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "firebase", {doc: ["events", storeFolderId, storeFolderId, cell]})).then((res) => {
                    if(res.response.error) {
                        setTableValues(res.response.error)
                    } else {
                        setTableNote("")
                    }
                    refreshValues()
                })
            }
        }
    }

    function deleteValueVideo(cell) {
        console.log(cell)
        console.log(`${editId}/${cell}`)
        if (socket !== null && socket.isOpened) {
            setTableValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("DELETE", "firebase_storage", {filePath: `${editId}/${cell}`})).then((res) => {
                    if(res.response.error) {
                        setTableValues(res.response.error)
                    } else {
                        setTableNote("")
                    }
                    refreshValues()
                })
            }
        }
    }

    function updateValue(cell) {
        //return;
        if (socket !== null && socket.isOpened) {
            setTableValues([]);
            setLoading(true);
            if (socket !== null && socket.isOpened) {
                socket.sendRequest(prepareSystemServiceQuery("PUT", "firebase", {updatedData: cell, doc: ["events", storeFolderId, storeFolderId, cell.id]})).then((res) => {
                    if(res.response.error) {
                        setTableValues(res.response.error)
                    } else {
                        setTableNote("")
                    }
                    refreshValues()
                })
            }
        }
    }

    function addFrame1(event) {
        console.log(event.target.files[0])
        setFileImageFrame1(event.target.files[0]);
        setImageFrameName1(event.target.files[0].name)
    }

    function addFrame2(event) {
        setFileImageFrame2(event.target.files[0]);
        setImageFrameName2(event.target.files[0].name)
    }

    function addGuestAppLogo(event) {
        setGuestAppImage(event.target.files[0]);
        setGuestAppImageName(event.target.files[0].name)
    }

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    function handleChangeInput(i, event, array, setMethod) {
        const values = [...array];
        const { name, value } = event.target;
        values[i][name] = value;
        setMethod(values);
    }

    //Validate that everything is in order
    async function validationInputs() {

        return true;
    }

    async function makeRule() {
        if (!(await validationInputs())) {
            //setMessage("Some fields contain errors");
            return false;
        }
        const promiseArr = []
        if(fileImageFrame1 !== "") {
            promiseArr.push(uploadFile(fileImageFrame1, "Event Frame 1", fileImageFrameName1))
        }
        if(fileImageFrame2 !== "") {
            promiseArr.push(uploadFile(fileImageFrame2, "Event Frame 2", fileImageFrameName2))
        }
        if(guestAppImage !== "") {
            promiseArr.push(uploadFileToFirebase(guestAppImage, guestAppImageName))
        }
        
        var uploadedData = {}

        if(promiseArr.length === 0) {
            updateData(uploadedData)
        } else {
            Promise.all(promiseArr)
            .then((res) => {
                console.log(res)
                for(var i = 0; i < res.length; i++) {
                    console.log(res[i])
                    if(res[i].reference === fileImageFrameName1) {
                        uploadedData = {...uploadedData, imageFrame1: res[i].id}
                    }
                    if(res[i].reference === fileImageFrameName2) {
                        uploadedData = {...uploadedData, imageFrame2: res[i].id}
                    }
                    if(res[i].reference === guestAppImageName) {
                        uploadedData = {...uploadedData, guestAppImage: res[i].url}
                    }
                }
                updateData(uploadedData)
            })
            .catch(err => {
                console.log(err)
            })
        }
    }

    function updateData(uploadedData) {
        console.log(uploadedData)
        //Creates the final rule based on the prepared alias names
        var finalRule = {
            participatingBranches: selectedBranches.map(branch => branch.value),
            title: title,
            startDate: startDate,
            endDate: endDate,
            sponsers: sponsers,
            selectedFrameType: selectedFrameType.value,
            ...uploadedData
            //appParams: appParams.filter(val => val.label !== "")
        };
        if(contentEditor1 !== "") {
            finalRule.guestAppBottomText = contentEditor1
        }
        if(contentEditor !== "") {
            finalRule.guestAppTopText = contentEditor
        }
        if(guestAppColor !== "") {
            finalRule.guestAppColor = guestAppColor
        }
        console.log(finalRule)

        if (socket !== null && socket.isOpened) {
            if(isEdit) {
                finalRule.id = editId
            }
            console.log(finalRule)
            socket.sendRequest(prepareSystemServiceQuery(isEdit ? "PUT" : "POST", "events", finalRule)).then(async (res) => {
                console.log(res)
                const v = await forceLayoutChange()
                if (res.response.error === undefined) {
                    toast.success(contentSuccess);
                    setOperation(true);
                } else {
                    setMessage(res.response.error)
                }
            });
        }
    }

    function uploadFile(file, name, reference) {
        return new Promise ((resolve, reject) => {
            const formData = new FormData();
            let headers = new Headers();

            formData.append('file', file);
            formData.append("title", name);
            formData.append("description", name);

            headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);
            fetch(`${localStorage.getItem("serverIp")}/file`, {
                method: 'POST',
                headers: headers,
                body: formData,
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success) {
                    console.log(res)
                    resolve({id: res.file._id, reference: reference})
                } else {
                    reject(res.message)
                }
            })
            .catch((error) => {
                reject(error)
            });
        })
        
    }

    function uploadFileToFirebase(file, reference) {
        return new Promise ((resolve, reject) => {
            const formData = new FormData();
            let headers = new Headers();

            formData.append('file', file);
            formData.append('eventId', editId);

            headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);
            fetch(`${localStorage.getItem("serverIp")}/upload_photo_firebase`, {
                method: 'POST',
                headers: headers,
                body: formData,
            })
            .then((response) => response.json())
            .then((res) => {
                console.log(res)
                if(res.success) {
                    resolve({url: res.url, reference: reference})
                } else {
                    reject(res.message)
                }
            })
            .catch((error) => {
                reject(error)
            });
        })
        
    }

    async function forceLayoutChange() {
        const branches = await socket.sendRequest(prepareSystemServiceQuery("GET", "branch", {}))
        if(branches.response.error === undefined) {
            var itemsToChange = branches.response.filter(item => item.company === "669f84b8f4f22e5f4217e6cd").map(item => (socket.sendRequest({ request_type: "Remotes", uri: "layout", data: {
                branch: item._id,
                layout: item.selectedLayout
            }})))
            console.log(itemsToChange)
            return Promise.all(itemsToChange)
            .then(async i => {
                console.log(i)
                console.log("all good")
                return "good"
            })
        } else {
            console.log("nothing chanfed")
            return "aaaa"
        }
        /*return socket.sendRequest(prepareSystemServiceQuery("GET", "branch", {})).then((res) => {
            console.log(res)
            if (res.response.error === undefined) {
                for (let index = 0; index < res.response.length; index++) {
                    const element = res.response[index];
                    if(element.company === "669f84b8f4f22e5f4217e6cd") {
                        socket.sendRequest({ request_type: "Remotes", uri: "layout", data: {branch: element._id, layout: ""} }).then((res) => {
                            console.log("layout changed")
                        })
                    }
                    
                }
            } else {
            }
        });*/
    }

        const handleDownload = async (folderPath) => {
            isZipping(true)
          //const folderPath = 'your/folder/path'; // Replace with the actual folder path
          const token = `${localStorage.getItem("wsToken")}`; // Replace with the actual JWT token
      
          try {
            const response = await fetch(`${localStorage.getItem("serverIp")}/download-firebase-zip?path=${encodeURIComponent(folderPath)}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${folderPath}.zip`;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            isZipping(false)
          } catch (error) {
            console.error('Error downloading the zip file:', error);
          }
        };

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/specular/events" />;
    }

    if(dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title={`${isEdit ? "Event Screen" : "New Event"}`}
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Title <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={title} onChange={e => setTitle(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="branches" id="branchLabel" sm={3}>
                                        Branches
                                    </Label>
                                    <HelpPopup target="branchLabel" header="Branches">
                                        Set the branches that will use this customization
                                    </HelpPopup>
                                    <Col sm={9}>
                                        <ReactSelect
                                            value={selectedBranches} 
                                            isMulti 
                                            onChange={e => {console.log(e); setSelectedBranches(e)}} 
                                            options={branches}
                                            styles={{
                                                control: (provided, state) => ({
                                                  ...provided,
                                                  background: '#F9FAFC',
                                                  borderColor: '#DEE2E6',
                                                  minHeight: '30px',
                                                  height: '30px',
                                                  boxShadow: state.isFocused ? null : null,
                                                }),
                                            
                                                valueContainer: (provided, state) => ({
                                                  ...provided,
                                                  height: '30px',
                                                  padding: '0 6px'
                                                }),
                                            
                                                input: (provided, state) => ({
                                                  ...provided,
                                                  margin: '0px',
                                                }),
                                                indicatorSeparator: state => ({
                                                  display: 'none',
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                  ...provided,
                                                  height: '30px',
                                                }),
                                                multiValue: (provided, state) => ({
                                                    ...provided,
                                                    height: '18px',
                                                  }),
                                                  multiValueLabel: (provided, state) => ({
                                                      ...provided,
                                                      fontSize: 13,
                                                      alignItems: "center",
                                                      display: "flex"
                                                    })
                                              }}
                                            />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="logo" id="logoLabel" sm={3}>
                                        Photo Frame 1 (Only Logo)
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={fileImageFrameName1 || 'choose an image file'}
                                            accept="image/*"
                                            onChange={addFrame1}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="logo" id="logoLabel2" sm={3}>
                                        Photo Frame 2 (Full Frame)
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={fileImageFrameName2 || 'choose an image file'}
                                            accept="image/*"
                                            onChange={addFrame2}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="branches" id="branchLabel" sm={3}>
                                        Selected Frame
                                    </Label>
                                    <Col sm={9}>
                                        <ReactSelect
                                            value={selectedFrameType} 
                                            onChange={e => {setSelectedFrameType(e)}} 
                                            options={[{value: 1, label: "Frame 1"}, {value: 2, label: "Frame 2"}]}
                                            styles={{
                                                control: (provided, state) => ({
                                                  ...provided,
                                                  background: '#F9FAFC',
                                                  borderColor: '#DEE2E6',
                                                  minHeight: '30px',
                                                  height: '30px',
                                                  boxShadow: state.isFocused ? null : null,
                                                }),
                                            
                                                valueContainer: (provided, state) => ({
                                                  ...provided,
                                                  height: '30px',
                                                  padding: '0 6px'
                                                }),
                                            
                                                input: (provided, state) => ({
                                                  ...provided,
                                                  margin: '0px',
                                                }),
                                                indicatorSeparator: state => ({
                                                  display: 'none',
                                                }),
                                                indicatorsContainer: (provided, state) => ({
                                                  ...provided,
                                                  height: '30px',
                                                }),
                                                multiValue: (provided, state) => ({
                                                    ...provided,
                                                    height: '18px',
                                                  }),
                                                  multiValueLabel: (provided, state) => ({
                                                      ...provided,
                                                      fontSize: 13,
                                                      alignItems: "center",
                                                      display: "flex"
                                                    })
                                              }}
                                            />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="logo" id="logoLabel2" sm={3}>
                                        Date Range
                                    </Label>
                                    <Col sm={9}>
                                    <div className="d-flex">
                                        <InputGroup size="sm">
                                            <InputGroupAddon addonType="prepend">From</InputGroupAddon>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                showTimeInput
                                                dateFormat="dd/MM HH:mm"
                                                customInput={<InputButton />}
                                            />
                                            <InputGroupAddon addonType="prepend">To</InputGroupAddon>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                showTimeInput
                                                dateFormat="dd/MM HH:mm"
                                                customInput={<InputButton />}
                                            />
                                        </InputGroup>
                                        </div>
                                    </Col>
                                </FormRow>
                                
                            </Form>
                        </CardBody>
                    </Card>
                    {
                        isEdit &&
                        <Card className="mb-3">
                            <CardHeader>
                                Guest App
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <FormRow>
                                        <Label sm={3}>
                                            Logo
                                        </Label>
                                        <Col sm={9}>
                                            <RSInput
                                                bsSize='sm'
                                                type="file"
                                                label={guestAppImageName || 'choose an image file'}
                                                accept="image/*"
                                                onChange={addGuestAppLogo}
                                                modifiers={{
                                                    setMaxHeight: {
                                                        enabled: true,
                                                        order: 890,
                                                        fn: (data) => {
                                                            return {
                                                                ...data,
                                                                styles: {
                                                                    ...data.styles,
                                                                    overflow: 'auto',
                                                                    maxHeight: '200px',
                                                                },
                                                            };
                                                        }
                                                    }
                                                }} />
                                        </Col>
                                    </FormRow>
                                    <FormRow>
                                        <Label for="appColor" id="appColorLabel" sm={3}>
                                            App Bar Color
                                        </Label>
                                        <HelpPopup target="appColorLabel" header="App Color">
                                            Use HTML Colors to style to app bar (or use transparent)
                                        </HelpPopup>
                                        <Col sm={9}>
                                            <Input type="text" id="guestAppColor" maxLength="52" value={guestAppColor} onChange={e => setGuestAppColor(e.target.value)} />
                                        </Col>
                                    </FormRow>
                                    <FormRow>
                                        <Label sm={3}>
                                            Top Text
                                        </Label>
                                    </FormRow>
                                    <FormRow style={{justifyContent: "center"}}>
                                        <Editor
                                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            init={{
                                            height: 500,
                                            min_width: "300px",
                                            menubar: false,
                                            plugins: [
                                                'advlist', 'autolink', 'lists'/*, 'link'*/, 'image', 'charmap',
                                                'visualblocks', 'visualchars', 'fullscreen', 'emoticons',
                                                'help', 'wordcount', 'directionality', 'formatpainter',  'code', 'link', 'image'
                                            ],
                                            valid_children : '+body[style]',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | ' +
                                                'bold italic forecolor | alignleft aligncenter alignright | ' +
                                                'ltr rtl | emoticons | link image |' +
                                                'bullist numlist lineheight | ' +
                                                'removeformat | code | help',
                                                font_family_formats: "Andale Mono;Arial;Arial Black;Book Antiqua;Calibri;Sans-Serif;Comic Sans MS;Curier New;Georgia;Helvetica;Impact;Monospace;Symbol;Tahoma;Terminal;Times New Roman;Trebuchet MS;Verdana;Webdings;Wingdings",
                                                content_style: 'body { font-family:Calibri,Helvetica,Arial,sans-serif; font-size:16px }'
                                            }}
                                            value={contentEditor}
                                            onEditorChange={handleEditorChange}
                                        />
                                    </FormRow>
                                    <FormRow>
                                        <Label sm={3}>
                                            Bottom Text
                                        </Label>
                                    </FormRow>
                                    <FormRow style={{justifyContent: "center"}}>
                                        <Editor
                                            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                            onInit={(evt, editor) => editorRef1.current = editor}
                                            init={{
                                            height: 500,
                                            min_width: "300px",
                                            menubar: false,
                                            plugins: [
                                                'advlist', 'autolink', 'lists'/*, 'link'*/, 'image', 'charmap',
                                                'visualblocks', 'visualchars', 'fullscreen', 'emoticons',
                                                'help', 'wordcount', 'directionality', 'formatpainter',  'code', 'link', 'image'
                                            ],
                                            valid_children : '+body[style]',
                                            toolbar: 'undo redo | blocks fontfamily fontsize | ' +
                                                'bold italic forecolor | alignleft aligncenter alignright | ' +
                                                'ltr rtl | emoticons | link image |' +
                                                'bullist numlist lineheight | ' +
                                                'removeformat | code | help',
                                                font_family_formats: "Andale Mono;Arial;Arial Black;Book Antiqua;Calibri;Sans-Serif;Comic Sans MS;Curier New;Georgia;Helvetica;Impact;Monospace;Symbol;Tahoma;Terminal;Times New Roman;Trebuchet MS;Verdana;Webdings;Wingdings",
                                                content_style: 'body { font-family:Calibri,Helvetica,Arial,sans-serif; font-size:16px }'
                                            }}
                                            value={contentEditor1}
                                            onEditorChange={handleEditorChange1}
                                        />
                                    </FormRow>
                                    
                                </Form>
                            </CardBody>
                        </Card>
                    }
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{isEdit ? "Edit" : "Add"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                        </Col>
                        { fileImageFrameName1 !== "" && fileImageFrameName1.includes("67d") && <img src={`https://specularcenter.onrender.com/file/${fileImageFrameName1}`} alt="Hotel Logo" style={{marginLeft: "10px", width: "100px"}} /> }
                        { /*fileImageFrameName1 !== "" && !fileImageFrameName1.includes("67d") && <img src={URL.createObjectURL(`${fileImageFrame1}`)} alt="Hotel Logo" style={{marginLeft: "10px", width: "100px"}} />*/ }
                        
                        { fileImageFrameName2 !== "" && fileImageFrameName2.includes("67d") && <img src={`https://specularcenter.onrender.com/file/${fileImageFrameName2}`} alt="Hotel Logo" style={{marginLeft: "10px", width: "100px"}} /> }
                        { /*fileImageFrameName2 !== "" && !fileImageFrameName2.includes("67d") && <img src={URL.createObjectURL(`${fileImageFrame2}`)} alt="Hotel Logo" style={{marginLeft: "10px", width: "100px"}} />*/ }
                    </FormRow>
                    {
                        isEdit &&
                        <Card className="mb-3 mt-3">
                            <CardHeader>
                                Contact Drive
                            </CardHeader>
                            <CardBody>
                                <FormRow>
                                    <Label sm={3}>
                                        Get Images Link
                                    </Label>
                                    <Col sm={9}>
                                        <Button color="success" onClick={() => { navigator.clipboard.writeText("http://otg.specularo.com/total_images/" + storeFolderId + "?isservice=true"); }} >
                                            Link
                                        </Button>
                                    </Col>
                                </FormRow>
                                <AdvancedTable
                                    //to="/specular/events/add"
                                    loading={loading}
                                    tableNote={<div className='text-red'>{tableNote}</div>}
                                    extra={<Button disabled={zipping} onClick={() => handleDownload(storeFolderId)}>Download ZIP</Button>}
                                    columns={[{
                                            dataField: 'name',
                                            text: 'Name',
                                            sort: true
                                        }, {
                                            dataField: 'phone',
                                            text: 'Phone',
                                            sort: true
                                        }, {
                                            dataField: 'appliedTerms',
                                            text: 'Applied Terms',
                                            sort: true,
                                            formatter: function name(cell) {
                                                return cell ? <i className="fa fa-fw fa-circle text-success ml-auto align-self-center"></i> : <i className="fa fa-fw fa-circle text-danger ml-auto align-self-center"></i>
                                            }
                                        }, {
                                            dataField: 'photos',
                                            text: 'Photo',
                                            sort: false,
                                            formatter: function name(cell) {
                                                var newPhoto = cell && cell.length > 0 ? cell[0].replace(storeFolderId, storeFolderId + "%2Fthumbs") : ""
                                                newPhoto = newPhoto.replace(".jpg", "_216x384.jpg")
                                                return <img src={cell && cell.length > 0 ? newPhoto : ""} alt=" " style={{width: "100px"}} />
                                            }
                                        }, {
                                            dataField: "actions",
                                            text: "Actions",
                                            sort: false,
                                            formatter: function name(cell) {
                                                return (
                                                    <ButtonGroup>
                                                        <UpdateModal assetName={cell[0]}
                                                            successFunction={updateValue}
                                                            successFunctionProp={cell[1] + ""}
                                                            cell={cell[2]}
                                                            />
                                                        <DeletionModal assetName={cell[0]}
                                                            successFunction={() => deleteValue(cell[1])}
                                                            successFunctionProp={cell[1] + ""} />
                                                        
                                                    </ButtonGroup>
                                                );
                                            } 
                                        }
                                    ]}
                                    keyField="id"
                                    data={
                                        Array.isArray(tableValues) ? tableValues.map(returnResource => ({
                                            ...returnResource,
                                            actions: [returnResource.name, returnResource.id, {...returnResource}]
                                        })) : []
                                    }
                                />
                            </CardBody>
                        </Card>
                    }
                    
                    {
                        isEdit &&
                        <Card className="mb-3 mt-3">
                            <CardHeader>
                                Videos Drive
                            </CardHeader>
                            <CardBody>
                                <FormRow>
                                    <Label sm={3}>
                                        Get Videos Link
                                    </Label>
                                    <Col sm={9}>
                                        <Button color="success" onClick={() => { navigator.clipboard.writeText("http://otg.specularo.com/total_videos/" + storeFolderId + "?isservice=true"); }} >
                                            Link
                                        </Button>
                                    </Col>
                                </FormRow>
                                <AdvancedTable
                                    //to="/specular/events/add"
                                    loading={loading}
                                    tableNote={<div className='text-red'>{tableNote}</div>}
                                    extra={<Button disabled={zipping} onClick={() => handleDownload(editId)}>Download ZIP</Button>}
                                    columns={[{
                                            dataField: 'name',
                                            text: 'Name',
                                            sort: true
                                        }, {
                                            dataField: 'url',
                                            text: 'URL',
                                            sort: true
                                        }, {
                                            dataField: "actions",
                                            text: "Actions",
                                            sort: false,
                                            formatter: function name(cell) {
                                                return (
                                                    <ButtonGroup>
                                                        <DeletionModal assetName={cell[0]}
                                                            successFunction={() => deleteValueVideo(cell[0])}
                                                            successFunctionProp={cell[0] + ""} />
                                                        
                                                        <Button color="success" tag={Link} target="_blank" to={{ pathname: cell[1].url}} download="video.mp4">
                                                            Download
                                                        </Button>
                                                    </ButtonGroup>
                                                );
                                            } 
                                        }
                                    ]}
                                    keyField="name"
                                    data={
                                        Array.isArray(videos) ? videos.map(returnResource => ({
                                            ...returnResource,
                                            actions: [returnResource.name, {...returnResource}]
                                        })) : []
                                    }
                                />
                            </CardBody>
                        </Card>
                    }
                    
                    
                </Col>
            </Row>

        </Container>

    );
}

EditEvent.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default EditEvent;

