import React from 'react';
import PropTypes from 'prop-types';
import {
    Button
} from 'reactstrap';
import { getMetaInfo, transform, save } from './Utils/csvExporter';
import _ from "lodash"

export const CustomExportCSV = ({children, onExport, ...props}) => {
    const csvDefaultOptions = {
        fileName: 'spreadsheet.csv',
        separator: ',',
        ignoreHeader: false,
        ignoreFooter: true,
        noAutoBOM: false,
        blobType: 'text/csv;charset=utf-8',
        exportAll: true,
        onlyExportSelection: false
      };

    const handleExportCSV = (source) => {
        const { columns } = props;
        const meta = getMetaInfo(columns);
        const options = csvDefaultOptions
  
        // get data for csv export
        let data;
        if (typeof source !== 'undefined') {
          data = source;
        } else if (options.exportAll) {
          data = props.data;
        }
    
        /*
        // filter data by row selection
        if (options.onlyExportSelection) {
          const payload = {};
          this.tableExposedAPIEmitter.emit('get.selected.rows', payload);
          const selections = payload.result;
          data = data.filter(row => !!selections.find(sel => row[keyField] === sel));
        }*/
  
        const content = transform(data, meta, columns, _, options);
        save(content, options);
      }

    return (
        <Button
            { ...props }
            onClick={() => { handleExportCSV(props.data) }}
        >
            { children }
        </Button>
    );
}

CustomExportCSV.propTypes = {
    size: PropTypes.string,
    outline: PropTypes.bool,
    onExport: PropTypes.func,
    children: PropTypes.node
}

CustomExportCSV.defaultProps = {
    size: 'sm',
    outline: true
}