import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
    FormGroup,
    FormText,
    Input,
    Button,
    Label,
    DropdownItem
} from "reactstrap"

import { HeaderAuth } from "../../components/AppInfo/HeaderAuth";
import { FooterAuth } from "../../components/AppInfo/FooterAuth";
import EmptyLayout from '../../components/EmptyLayout';
import { useSocket } from '../../contexts/SocketContext';
import themeContext from '../../components/Theme/ThemeContext';
import { ThemeConsumer } from '../../components/Theme';
import { Dropdown } from '../../components/Dropdown';

function Login() {
    const [settingsExist, setSettingsExist] = useState(true);
    const [isLoggedIn, setLoggedIn] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [availableServers, setAvailableServers] = useState(["https://specularcenter.onrender.com", "https://specular-center.herokuapp.com"])
    const [serverIp, setServerIp] = useState("https://specularcenter.onrender.com");
    const { setSocketConn } = useSocket();
    const theme = useContext(themeContext)

    useEffect(() => {
        fetch((serverIp === "" ? window.location.hostname : serverIp) + "/firstrun", {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          }).then(res => res.json())
          .then(res => {
              setSettingsExist(res.appExists);
          })
    }, [])

    function postLogin(e) {
        e.preventDefault();

        if (serverIp !== "") {
            localStorage.setItem("serverIp", serverIp)
        }

        if (userName === "") {
            setIsError(true);
            setErrorMsg("Enter Username");
            return false;
        }
        else {
            setIsError(false);
            setErrorMsg("");
        }
        if (password === "") {
            setIsError(true);
            setErrorMsg("Enter Password");
            return false;
        }
        else {
            setIsError(false);
            setErrorMsg("");
        }

        fetch((serverIp) + "/auth", {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: JSON.stringify({
                user: {
                    username: userName,
                    password: password
                }
            }) // body data type must match "Content-Type" header
          }).then(res => {
                if(res.status!==200) {
                    return Promise.reject(res);
                }
              return res.json();
            })
            .then(res => {
                //After getting the token, send it to the callback reconnection
                setSocketConn(res.token);
                localStorage.setItem("wsToken", res.token);
                localStorage.setItem("language", res.language);
                localStorage.setItem("types", res.types);
                setLoggedIn(true);

                switch(res.language) {
                    case "עברית": theme.onChangeTheme({ dir: "rtl" }); break;
                    default: theme.onChangeTheme({ dir: "ltr" }); break;
                }
            })
            .catch(e => {
                setIsError(true);
                try {
                    e.json().then(res => {
                        setErrorMsg(res.errors.message);
                    })
                }
                catch(error) {
                    setErrorMsg("Connection failed, please contact the vendor")
                }
            })
    }

    if (isLoggedIn) {
        return <Redirect to="/" />;
    }

    if(!settingsExist) {
        return <Redirect to="/pages/firstrun" />
    }

    return (

        <EmptyLayout>
            <EmptyLayout.Section center>
                <HeaderAuth
                    title="Sign In to Specular Cloud"
                />
                <form className="mb-3">
                    <FormGroup>
                        <FormText color="red" style={{ textAlign: "center" }}>
                            {isError && <div>{errorMsg}</div>}
                        </FormText>
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label for="server">
                            Server IP (Keep Empty for localhost)
                        </Label>
                        <br />
                        <Dropdown size="md" style={{width: "100%"}} toggle={availableServers.filter(value => value === serverIp).length === 0 ? "other" : serverIp}>
                            {
                                availableServers.map(server => (
                                    <DropdownItem key={server} onClick={() => setServerIp(server)}>
                                        {server}
                                    </DropdownItem>
                                ))
                            }
                            <DropdownItem onClick={() => setServerIp("")}>
                                other
                            </DropdownItem> 
                        </Dropdown>
                        {
                            availableServers.filter(value => value === serverIp).length === 0 &&
                            <Input className='mt-3' type="text" name="user" id="server" value={serverIp}
                                onChange={e => {
                                    setServerIp(e.target.value);
                                }} placeholder="Enter Server IP" required />
                        }
                    </FormGroup>

                    <FormGroup className="mb-3">
                        <Label for="username">
                            Email
                        </Label>
                        <Input type="text" name="user" id="username" value={userName}
                            onChange={e => {
                                setUserName(e.target.value);
                            }} placeholder="Enter Username..." autoComplete="username" required />
                    </FormGroup>
                    <FormGroup className="mb-3">
                        <Label for="password">
                            Password
                        </Label>
                        <Input type="password" name="password" id="password" value={password}
                            onChange={e => {
                                setPassword(e.target.value);
                            }} placeholder="Password..." autoComplete="current-password" required />
                    </FormGroup>
                    <ThemeConsumer>
                        {
                            ({ color }) => (

                                <Button type="submit" onClick={postLogin} block color={color}>Sign In</Button>
                            )
                        }
                    </ThemeConsumer>

                </form>
                <FooterAuth />
            </EmptyLayout.Section>
        </EmptyLayout>
    );
}

export default Login;