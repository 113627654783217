import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Form,
    Label,
    Media,
    CardHeader,
    DropdownItem,
    InputGroupAddon,
    InputGroupText,
    Input as RSInput,
} from 'reactstrap';
import { Button, Dropdown, FormRow, Input, InputGroup } from '../../components';
import { ThemeConsumer } from "../../components/Theme";
import { toast } from 'react-toastify';
import { HeaderMain } from "../../components/HeaderMain";
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSocket, prepareSystemServiceQuery } from '../../contexts/SocketContext';
import HelpPopup from '../../components/helpPopup';
import PageLoad from '../../components/PageLoad';
import ReactSelect from '../../components/AdvancedSelect';

function General(props) {
    const { socket } = useSocket();
    const [dataLoading, setDataLoading] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [editId, setEditId] = useState("");
    const [isOperationSuccessful, setOperation] = useState(false); //Summary of form
    const [message, setMessage] = useState(""); //The used message in case of an error

    const [companies, setCompanies] = useState([])
    const [services, setServices] = useState([])
    const [layouts, setLayouts] = useState([{value: 1, label: 1}, {value: 2, label: 2}, {value: 3, label: 3}, {value: 4, label: 4}, {value: 5, label: 5}, {value: 6, label: 6}, {value: 7, label: 7}, {value: 8, label: 8}, {value: 9, label: 9}, {value: 10, label: 10},])

    const [hotelName, setHotelName] = useState("")
    const [company, setCompany] = useState("")
    const [type, setType] = useState("")
    const [selectedLayout, setSelectedLayout] = useState({value: 1, label: 1})
    
    const [file, setFile] = useState("")
    const [fileName, setFileName] = useState("")

    const [geoJsonFile, setGeoJsonFile] = useState("")
    const [geoJsonFileName, setGeoJsonFileName] = useState("")
    
    const [routingGeoJsonFile, setRoutingGeoJsonFile] = useState("")
    const [routingGeoJsonFileName, setRoutingGeoJsonFileName] = useState("")
    
    const [parkingMapGeoJsonFile, setParkingMapGeoJsonFile] = useState("")
    const [parkingMapGeoJsonFileName, setParkingMapGeoJsonFileName] = useState("")
    
    const [parkingRoutesGeoJsonFile, setParkingRoutesGeoJsonFile] = useState("")
    const [parkingRoutesGeoJsonFileName, setParkingRoutesGeoJsonFileName] = useState("")
    
    const [parkingMapRasterFile, setParkingMapRasterFile] = useState("")
    const [parkingMapRasterFileName, setParkingMapRasterFileName] = useState("")

    const [diningTypesAvailable, setAvailableDiningTypes] = useState(("None"))

    const [breakfastFrom, setBreakfastFrom] = useState("")
    const [breakfastTo, setBreakfastTo] = useState("")

    const [lunchFrom, setLunchFrom] = useState("")
    const [lunchTo, setLunchTo] = useState("")

    const [dinnerFrom, setDinnerFrom] = useState("")
    const [dinnerTo, setDinnerTo] = useState("")

    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)

    const [selectedServices, setSelectedServices] = useState([])

    useEffect(() => {
        const ruleObj = props.location.pathname.split("/")[4];

        if (socket !== null && socket.isOpened) {
            setDataLoading(true)
            Promise.all([
                socket.sendRequest(prepareSystemServiceQuery("GET", "branch")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "user/companies")),
                socket.sendRequest(prepareSystemServiceQuery("GET", "service"))
            ]).then(([branchInfo, companiesInfo, servicesInfo]) => {
                if (branchInfo.response !== undefined) {
                    branchInfo.response.forEach(item => {
                        if (item._id === ruleObj) {
                            setIsEdit(true)
                            setEditId(item._id)
                            setHotelName(item.name)
                            setCompany(item.company)
                            setType(item.type)

                            setLatitude(item.latitude)
                            setLongitude(item.longitude)
                            var diningOptions = []
                            if(item.diningTimes) {
                                if (item.diningTimes.breakfast) {
                                    diningOptions.push("Breakfast")
                                    setBreakfastFrom(item.diningTimes.breakfast.from)
                                    setBreakfastTo(item.diningTimes.breakfast.to)
                                }
                                if (item.diningTimes.lunch) {
                                    diningOptions.push("Lunch")
                                    setLunchFrom(item.diningTimes.lunch.from)
                                    setLunchTo(item.diningTimes.lunch.to)
                                }
                                if (item.diningTimes.dinner) {
                                    diningOptions.push("Dinner")
                                    setDinnerFrom(item.diningTimes.dinner.from)
                                    setDinnerTo(item.diningTimes.dinner.to)
                                }
                            }
                            if (diningOptions.length > 0) {
                                setAvailableDiningTypes(diningOptions.join(" + "))
                            } else {
                                setAvailableDiningTypes("None")
                            }
                            
                            if(item.selectedLayout) setSelectedLayout({value: item.selectedLayout, label: item.selectedLayout})
    
                            setSelectedServices(item.services.map(services => ({value: services._id, label: services.servicename, category: services.category})))
                            setDataLoading(false)
                        }
                    })
                }
                setCompanies(companiesInfo.response)
                setServices(servicesInfo.response.map(services => ({value: services._id, label: services.servicename, category: services.category})))
            }).then(() => {
                if(!isEdit) {
                    setDataLoading(false)
                }
            })
            
        }
    }, [socket]);

    function setMultiple(event, updateMethod) {
        let opts = [], opt;
        for (let i = 0, len = event.target.options.length; i < len; i++) {
            opt = event.target.options[i];
            if (opt.selected) {
                opts.push(opt.value);
            }
        }
        updateMethod(opts);
    }

    //Validate that everything is in order
    async function validationInputs() {
        console.log("asddd")
        console.log(selectedServices)
        if(selectedServices.filter(service => service.category === "pms").length > 1) {
            setMessage("Only one PMS service can be used")
            console.log("asd")
            return false
        }
        return true;
    }

    function parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }

    /**
     * Creates the aliases and rule using the input from the client.
     */
    async function makeRule() {
        if (!(await validationInputs())) {
            //setMessage("Some fields contain errors");
            return false;
        }
        const promiseArr = []
        if(file !== "") {
            promiseArr.push(uploadFile(file, hotelName + " Logo", "logo"))
        }
        if(geoJsonFile !== "") {
            promiseArr.push(uploadFile(geoJsonFile, hotelName + " Maps Object", "maps"))
        }
        if(routingGeoJsonFile !== "") {
            promiseArr.push(uploadFile(routingGeoJsonFile, hotelName + " Routing Object", "routes"))
        }
        if(parkingMapGeoJsonFile !== "") {
            promiseArr.push(uploadFile(parkingMapGeoJsonFile, hotelName + " Parking Maps Object", "parkingMaps"))
        }
        if(parkingRoutesGeoJsonFile !== "") {
            promiseArr.push(uploadFile(parkingRoutesGeoJsonFile, hotelName + " Parking Routing Object", "parkingRoutes"))
        }
        if(parkingMapRasterFile !== "") {
            promiseArr.push(uploadFile(parkingMapRasterFile, hotelName + " Parking Map Raster Object", "parkingRaster"))
        }

        if(promiseArr.length === 0) {
            updateData(undefined)
        } else {
            Promise.all(promiseArr)
            .then((res) => {
                var logo = undefined
                var mapsGeoJson = undefined
                var routesGeoJson = undefined
                var parkingMapsGeoJson = undefined
                var parkingRoutesGeoJson = undefined
                var parkingRaster = undefined
                for(var i = 0; i < res.length; i++) {
                    if(res[i].reference === "logo") {
                        logo = res[i].id
                    }
                    if(res[i].reference === "maps") {
                        mapsGeoJson = res[i].id
                    }   
                    if(res[i].reference === "routes") {
                        routesGeoJson = res[i].id
                    }
                    if(res[i].reference === "parkingMaps") {
                        parkingMapsGeoJson = res[i].id
                    }
                    if(res[i].reference === "parkingRoutes") {
                        parkingRoutesGeoJson = res[i].id
                    }
                    if(res[i].reference === "parkingRaster") {
                        parkingRaster = res[i].id
                    }
                }
                updateData(logo, mapsGeoJson, routesGeoJson, parkingMapsGeoJson, parkingRoutesGeoJson, parkingRaster)
            })
        }
    }

    function uploadFile(file, name, reference) {
        return new Promise ((resolve, reject) => {
            const formData = new FormData();
            let headers = new Headers();
            console.log(file)
            console.log(name)

            formData.append('file', file);
            formData.append("title", name);
            formData.append("description", name);

            headers.set('Authorization', `Basic ${localStorage.getItem("wsToken")}`);
            fetch(`${localStorage.getItem("serverIp")}/file`, {
                method: 'POST',
                headers: headers,
                body: formData,
            })
            .then((response) => response.json())
            .then((res) => {
                if(res.success) {
                    console.log(res)
                    resolve({id: res.file._id, reference: reference})
                } else {
                    reject(res.message)
                }
            })
            .catch((error) => {
                reject(error)
            });
        })
        
    }

    function addFile(event) {
        setFile(event.target.files[0]);
        setFileName(event.target.files[0].name)
    }

    function addGeoJson(event) {
        setGeoJsonFile(event.target.files[0]);
        setGeoJsonFileName(event.target.files[0].name)
    }

    function addRoutingGeoJson(event) {
        setRoutingGeoJsonFile(event.target.files[0]);
        setRoutingGeoJsonFileName(event.target.files[0].name)
    }

    function addParkingMapgGeoJson(event) {
        setParkingMapGeoJsonFile(event.target.files[0]);
        setParkingMapGeoJsonFileName(event.target.files[0].name)
    }

    function addParkingRoutingGeoJson(event) {
        setParkingRoutesGeoJsonFile(event.target.files[0]);
        setParkingRoutesGeoJsonFileName(event.target.files[0].name)
    }

    function addParkingRaster(event) {
        setParkingMapRasterFile(event.target.files[0]);
        setParkingMapRasterFileName(event.target.files[0].name)
    }

    function updateData(logo, mapsGeoJson, routesGeoJson, parkingMapsGeoJson, parkingRoutesGeoJson, parkingRaster) {
        //Creates the final rule based on the prepared alias names
        var finalRule = {
            name: hotelName,
            company: company !== "" ? company : parseJwt(localStorage.getItem("wsToken")).company[0],
            logo: logo,
            type: type,
            longitude: longitude,
            latitude: latitude,
            services: selectedServices.map(service => service.value),
            selectedLayout: selectedLayout.value,
            diningTimes: {

            }
        };

        if(mapsGeoJson) {
            finalRule.mapsGeoJson = mapsGeoJson
        }
        if(routesGeoJson) {
            finalRule.routesGeoJson = routesGeoJson
        }

        
        if(parkingMapsGeoJson) {
            finalRule.parkingMapGeoJson = parkingMapsGeoJson
        }
        if(parkingRoutesGeoJson) {
            finalRule.parkingRoutesGeoJson = parkingRoutesGeoJson
        }
        if(parkingRaster) {
            finalRule.parkingMapRaster = parkingRaster
        }

        //todo: company is always the same, find out why and fix it

        if (diningTypesAvailable.includes("Breakfast")) {
            finalRule.diningTimes.breakfast = {
                from: breakfastFrom,
                to: breakfastTo
            }
        }

        if (diningTypesAvailable.includes("Lunch")) {
            finalRule.diningTimes.lunch = {
                from: lunchFrom,
                to: lunchTo
            }
        }

        if (diningTypesAvailable.includes("Dinner")) {
            finalRule.diningTimes.dinner = {
                from: dinnerFrom,
                to: dinnerTo
            }
        }
        if (socket !== null && socket.isOpened) {
            if(isEdit) {
                finalRule.id = editId
            }
            console.log(finalRule)
            socket.sendRequest(prepareSystemServiceQuery("PUT", "branch", finalRule)).then((res) => {
                console.log(res)
                if (res.response.error === undefined) {

                    socket.sendRequest({ request_type: "Remotes", uri: "layout", data: {branch: editId, layout: selectedLayout} }).then((res) => {
                        if(res.response !== undefined) {
                            console.log(res.response)
                        }
                        
                        toast.success(contentSuccess);
                        setOperation(true);
                    })
                } else {
                    setMessage(res.response.error)
                }
            });
        }
    }

    //Creates a toast on success
    const contentSuccess = ({ closeToast }) => (
        <Media>
            <Media middle left className="mr-3">
                <i className="fa fa-fw fa-2x fa-check"></i>
            </Media>
            <Media body>
                <Media heading tag="h6">
                    Success!
                </Media>
                <p>
                    You successfully added a new rule. This is effected immediately.
                </p>
                <div className="d-flex mt-2">
                    <Button color="success" onClick={() => { closeToast() }} >
                        Close
                    </Button>
                </div>
            </Media>
        </Media>
    );

    //Redirect on success
    if (isOperationSuccessful) {
        return <Redirect to="/app/branch" />;
    }

    if (dataLoading) {
        return (
            <PageLoad />
        )
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <HeaderMain
                        title="General"
                        subTitle="Update general information regaring your branch"
                        className="mb-3"
                    />
                </Col>
                <Col lg={12}>
                    <Label style={{ color: "red" }}>
                        {message}
                    </Label>
                    <Card className="mb-3">
                        <CardHeader>
                            General
                        </CardHeader>
                        <CardBody>
                            <Form>
                                {
                                    companies.filter(company => company.isAdmin).length > 0 &&
                                    <FormRow>
                                        <Label for="company" id="companyLabel" sm={3}>
                                            Company
                                        </Label>
                                        <Col sm={9}>
                                            <Dropdown toggle={company}>
                                                {
                                                    companies.map(availableCompany => (
                                                        <DropdownItem key={availableCompany._id} onClick={(e) => setCompany(availableCompany._id)}>
                                                            {availableCompany.slug}
                                                        </DropdownItem>
                                                    ))
                                                }
                                            </Dropdown>
                                        </Col>
                                    </FormRow>
                                }
                                <FormRow>
                                    <Label for="name" id="nameLabel" sm={3}>
                                        Branch Name <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <Input type="text" id="name" maxLength="52" value={hotelName} onChange={e => setHotelName(e.target.value)} required />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="logo" id="logoLabel" sm={3}>
                                        Logo URL
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={fileName || 'choose an image file'}
                                            accept="image/*"
                                            onChange={addFile}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="type" id="typeLabel" sm={3}>
                                        Type
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={type}>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Hotel
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Office
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Office Building
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setType(e.currentTarget.textContent)}>
                                                Mall
                                            </DropdownItem>
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="services" id="servicesLabel" sm={3}>
                                        Services
                                    </Label>
                                    <Col sm={9}>
                                        <ReactSelect 
                                            value={selectedServices} 
                                            onChange={e => {console.log(e); setSelectedServices(e)}} 
                                            options={services}
                                            isMulti={true}
                                            />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="selectedLayout" id="selectedLayout" sm={3}>
                                        Mirror Selected Layout
                                    </Label>
                                    <Col sm={9}>
                                        <ReactSelect 
                                            value={selectedLayout} 
                                            onChange={e => {console.log(e); setSelectedLayout(e)}} 
                                            options={layouts}
                                            />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label for="location" id="locationLabel" sm={3}>
                                        Location <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Latitude</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="number" id="latitude" maxLength="52" value={latitude} onChange={e => setLatitude(e.target.value)} required />
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>Longitude</InputGroupText>
                                            </InputGroupAddon>
                                            <Input type="number" id="location" maxLength="52" value={longitude} onChange={e => setLongitude(e.target.value)} required />
                                        </InputGroup>
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label sm={3}>
                                        Indoor Map File
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={geoJsonFileName || 'choose a geojson file'}
                                            accept=".json,.geojson"
                                            onChange={addGeoJson}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>

                                <FormRow>
                                    <Label sm={3}>
                                        Routing Map File
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={routingGeoJsonFileName || 'choose a geojson file'}
                                            accept=".json,.geojson"
                                            onChange={addRoutingGeoJson}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label sm={3}>
                                        Parking Map File
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={parkingMapGeoJsonFileName || 'choose a geojson file'}
                                            accept=".json,.geojson"
                                            onChange={addParkingMapgGeoJson}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label sm={3}>
                                        Parking Routing Map File
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={parkingRoutesGeoJsonFileName || 'choose a geojson file'}
                                            accept=".json,.geojson"
                                            onChange={addParkingRoutingGeoJson}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                <FormRow>
                                    <Label sm={3}>
                                        Parking Raster Map File
                                    </Label>
                                    <Col sm={9}>
                                        <RSInput
                                            bsSize='sm'
                                            type="file"
                                            label={parkingMapRasterFileName || 'choose a geojson file'}
                                            accept=".json,.geojson"
                                            onChange={addParkingRaster}
                                            modifiers={{
                                                setMaxHeight: {
                                                    enabled: true,
                                                    order: 890,
                                                    fn: (data) => {
                                                        return {
                                                            ...data,
                                                            styles: {
                                                                ...data.styles,
                                                                overflow: 'auto',
                                                                maxHeight: '200px',
                                                            },
                                                        };
                                                    }
                                                }
                                            }} />
                                    </Col>
                                </FormRow>
                                
                                <FormRow>
                                    <Label for="type" id="typeLabel" sm={3}>
                                        Dining Types Available
                                    </Label>
                                    <Col sm={9}>
                                        <Dropdown toggle={diningTypesAvailable}>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                None
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                Breakfast
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                Lunch
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                Breakfast + Lunch
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                Dinner
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                Breakfast + Dinner
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                Launch + Dinner
                                            </DropdownItem>
                                            <DropdownItem onClick={(e) => setAvailableDiningTypes(e.currentTarget.textContent)}>
                                                Breakfast + Lunch + Dinner
                                            </DropdownItem>
                                        </Dropdown>
                                    </Col>
                                </FormRow>
                            </Form>
                        </CardBody>
                    </Card>
                    {
                        diningTypesAvailable !== "None" &&
                        <Card className="mb-3">
                            <CardHeader>
                                Dining Hours
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    {
                                        diningTypesAvailable.includes("Breakfast") &&
                                        <FormRow>
                                            <Label for="breakfastTime" id="breakfastTimeLabel" sm={3}>
                                                Breakfast Time
                                            </Label>
                                            <Col sm={9}>
                                                <Input type="time" id="breakfastTime" maxLength="52" placeholder="From Time" value={breakfastFrom} onChange={e => setBreakfastFrom(e.target.value)} required />
                                                <Input type="time" id="breakfastTimeTo" maxLength="52" placeholder="To Time" value={breakfastTo} onChange={e => setBreakfastTo(e.target.value)} required />
                                            </Col>
                                        </FormRow>
                                    }
                                    {
                                        diningTypesAvailable.includes("Lunch") &&
                                        <FormRow>
                                            <Label for="lunchTime" id="lunchTimeLabel" sm={3}>
                                                Lunch Time
                                            </Label>
                                            <Col sm={9}>
                                                <Input type="time" id="lunchTime" maxLength="52" placeholder="From Time" value={lunchFrom} onChange={e => setLunchFrom(e.target.value)} required />
                                                <Input type="time" id="lunchTimeTo" maxLength="52" placeholder="To Time" value={lunchTo} onChange={e => setLunchTo(e.target.value)} required />
                                            </Col>
                                        </FormRow>
                                    }
                                    {
                                        diningTypesAvailable.includes("Dinner") &&
                                        <FormRow>
                                            <Label for="dinnerTime" id="dinnerTimeLabel" sm={3}>
                                                Dinner Time
                                            </Label>
                                            <Col sm={9}>
                                                <Input type="time" id="dinnerTime" maxLength="52" placeholder="From Time" value={dinnerFrom} onChange={e => setDinnerFrom(e.target.value)} required />
                                                <Input type="time" id="dinnerTimeTo" maxLength="52" placeholder="To Time" value={dinnerTo} onChange={e => setDinnerTo(e.target.value)} required />
                                            </Col>
                                        </FormRow>
                                    }
                                </Form>
                            </CardBody>
                        </Card>

                    }
                    <FormRow>
                        <Col sm={9}>
                            <ThemeConsumer>
                                {
                                    ({ color }) => (
                                        /* Make color as the same of parent */
                                        <Button color={color} outline={false} size="md" style={{ width: "100px" }} onClick={makeRule}>{"Update"}</Button>
                                    )
                                }
                            </ThemeConsumer>
                            {
                                file !== "" && <img src={URL.createObjectURL(file)} alt="Hotel Logo" style={{marginLeft: "10px", width: "100px"}} />
                            }
                        </Col>
                    </FormRow>
                </Col>
            </Row>

        </Container>

    );
}

General.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired
    }),
}
export default General;

